import InputComponent, { InputField } from "../input";
import DropdownComponent, { DropdownField } from "../dropdown";
import CheckboxComponent, { CheckboxField } from "../checkbox";
import SwitchComponent, { SwitchField } from "../switch";
import TextareaComponent, { TextareaField } from "../textarea";
import CheckboxGroupComponent, { CheckboxGroupField } from "../checkboxGroup";
import PasswordInputComponent, { PasswordInputField } from "../passwordInput";

import ButtonComponent from "../button";
import { IDynamicForm } from "./_type";
import { NvsDynamicForm } from "@nvs-dynamic-form/react-core";
import Card from "../card";

export default function DynamicForm({
  onSubmit = () => {},
  fields,
  submitButtonIsFullWidth,
  submitButtonLabel,
  submitButtonPosition,
  submitButtonVisible,
  containerVisible,
  useContainersOutsideGroup,
  useGroupContainer,
  containerOptions,
  formClass,
}: Readonly<IDynamicForm>) {
  return (
    <NvsDynamicForm
      onSubmit={onSubmit}
      submitButtonIsFullWidth={submitButtonIsFullWidth}
      submitButtonLabel={submitButtonLabel}
      submitButtonPosition={submitButtonPosition}
      submitButtonVisible={submitButtonVisible}
      fields={fields}
      buttonComponent={ButtonComponent}
      useContainersOutsideGroup={useContainersOutsideGroup}
      useGroupContainer={useGroupContainer}
      containerVisible={containerVisible}
      container={Card}
      formClass={formClass}
      containerOptions={containerOptions}
      submitButtonDefaultOptions={{
        label: "Send",
        isFullWidth: false,
        position: "right",
      }}
      submitButtonOptions={{
        isFullWidth: true,
      }}
      removeButtonDefaultOptions={{
        label: "",
        options: {
          iconName: "delete-bin-5-line",
          color: "danger",
        },
      }}
      addButtonDefaultOptions={{
        label: "Add",
        options: {
          isFullWidth: true,
          iconName: "add-circle-line",
          color: "success",
          style: { color: "white" },
        },
      }}
      formElements={{
        input: {
          component: InputComponent,
          class: InputField,
        },
        dropdown: {
          component: DropdownComponent,
          class: DropdownField,
        },
        checkbox: {
          component: CheckboxComponent,
          class: CheckboxField,
        },
        switch: {
          component: SwitchComponent,
          class: SwitchField,
        },
        textarea: {
          component: TextareaComponent,
          class: TextareaField,
        },
        checkboxGroup: {
          component: CheckboxGroupComponent,
          class: CheckboxGroupField,
        },
        passwordInput: {
          component: PasswordInputComponent,
          class: PasswordInputField,
        },
      }}
    />
  );
}
