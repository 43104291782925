export enum PermissionType {
  USER_TRANSACTIONS = "USER_TRANSACTIONS",
  COMPANY_TRANSACTIONS = "COMPANY_TRANSACTIONS",
  CORRIDOR_TRANSACTIONS = "CORRIDOR_TRANSACTIONS",
  SETTINGS_TRANSACTIONS = "SETTINGS_TRANSACTIONS",
  CUSTOMER_SUPPORT = "CUSTOMER_SUPPORT",
  CUSTOMER_SUPPORT_MANAGER = "CUSTOMER_SUPPORT_MANAGER",
  APP_TRANSACTIONS = "APP_TRANSACTIONS",
  ASSISTANT_TRANSACTIONS = "ASSISTANT_TRANSACTIONS",
  AGENT_TRANSACTIONS = "AGENT_TRANSACTIONS",
}
