import { ICompany } from "./company";
import { EAIType } from "./aiType";
import { ICompanyAssistant } from "./companyAssistant";

export interface IChatApp {
  id: string;
  provider: EChatAppProvider;
  company: ICompany;
  companyId: number;
  externalAppId: string;
  title: string;
  botName: string;
  settings?: IChatAppSettings | object;
  aiType?: EAIType;
  assistantId?: string | null;
  companyAssistant: ICompanyAssistant;
}

export enum EChatAppProvider {
  WHATSAPP = "WHATSAPP",
  WIDGET = "WIDGET",
}

export interface IChatAppSettings {
  widgetIconUrl: string;
  aiIconUrl: string;
  primaryBackgroundColor: string;
  primaryTextColor: string;
  contentBackgroundColor: string;
  footerBackgroundColor: string;
  inputBackgroundColor: string;
  inputTextColor: string;
  sentMessageBubbleBgColor: string;
  sentMessageBubbleTextColor: string;
  receivedMessageBubbleBgColor: string;
  receivedMessageBubbleTextColor: string;
}
