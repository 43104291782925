import { ICompany } from "./company";

export interface IAgent {
  id: string;
  companyId: number;
  name: string;
  intentionType: EIntentionType;
  createdAt: string;
  isActive: boolean;
  isGlobalAssistantAgent: boolean;
  company: ICompany;
  properties: IAgentProperty[];
  sources: IAgentSource[];
}

export interface IAgentProperty {
  key: string;
  description: string;
  type: "string" | "number" | "boolean";
  required: boolean;
}

export interface IAgentSource {
  name: string;
  description?: string;
  endpoint: string;
  requestType: "POST" | "PUT" | "PATCH";
  headers: IKeyValueObject[];
  body: IKeyValueObject[];
}

export interface IKeyValueObject {
  key: string;
  value: string;
}

export enum EIntentionType {
  CARGO_TRACKING = "CARGO_TRACKING",
}
