import {
  CardBody,
  CardHeader,
  Divider,
  Card as NextUICard,
} from "@nextui-org/react";

import { ICard } from "./_type";

export default function Card({
  title,
  subTitle,
  iconName,
  iconColor = "primary",
  children,
  className,
}: Readonly<ICard>) {
  return (
    <NextUICard className={className}>
      <CardHeader>
        {iconName && (
          <div
            className={`bg-${iconColor}/20 w-10 h-10 mr-3 flex items-center justify-center rounded-full`}
          >
            <i className={`${iconName} text-2xl`}></i>
          </div>
        )}
        <div className="flex flex-col">
          <p className="text-md">{title}</p>
          <p className="text-small text-default-500">{subTitle}</p>
        </div>
      </CardHeader>
      {title && <Divider /> }
      <CardBody>{children}</CardBody>
      <Divider />
    </NextUICard>
  );
}
